@use 'sass:meta';

@mixin -button-color($bg-color, $color) {
  @apply #{$bg-color} #{$color};
}

@mixin -button-hover($bg-color, $color) {
  @apply hover:#{$bg-color} hover:#{$color};
}

@mixin -button-hover-ring($ring-color, $ring-offset-color) {
  @apply focus:#{outline-none} focus:ring-2 focus:ring-offset-2 focus:ring-#{$ring-color} focus:ring-offset-#{$ring-offset-color};
}

@mixin -ripple-hover {
  @apply hover:scale-105;
}

.custom-button-component {
  @apply inline-flex items-center justify-center rounded-full px-6 py-3 text-sm font-medium transition-all;

  &.type-normal {
    @include -button-color(bg-white, text-black);
    @include -button-hover(bg-gray-100, text-black);
    @include -button-hover-ring(white, black);
  }

  &.type-contrast {
    @include -button-color(bg-black, text-white);
    @include -button-hover(bg-gray-800, text-white);
    @include -button-hover-ring(black, white);
  }
  
  &.type-secondary {
    @include -button-color(bg-slate-600, text-white);
    @include -button-hover(bg-slate-800, text-white);
    @include -button-hover-ring(slate-600, white);
  }

  &.type-ghost {
    @include -button-color(bg-transparent, text-gray-700);
    @include -button-hover(bg-transparent, text-gray-700);
    @include -button-hover-ring(gray-700, white);
  }

  &.ripple {
    @include -ripple-hover;
  }
}