@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@plugin "@tailwindcss/typography";

html,
body {
  font-family: "Archivo", sans-serif;
  font-optical-sizing: auto;
  scroll-behavior: smooth;
}
