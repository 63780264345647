.transcript {
  line-height: 1.6;
  text-align: center;
  margin: 0 auto;
  overflow-wrap: break-word;
}

.highlight {
  background-color: #48b3e4;
  text-shadow: 0px 0px 1px rgb(8, 58, 165);
}

.light-highlight {
  background-color: #6fd1ff;
}

.share-tooltip {
  margin-top: -2.4rem;
  margin-left: -4.3rem;
}