#in-page-player {
  input[type="range"] {
    -webkit-appearance: none;
    height: 8px;
    background: #F1F5F9;
    border-radius: 5px;
    background-image: linear-gradient(#38BDF8, #38BDF8);
    background-size: 0% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    width: 100%;
  }

  html.dark input[type="range"] {
    background: #0F172A;
    background-image: linear-gradient(#38BDF8, #38BDF8);
    background-size: 0% 100%;
    box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.06);
    background-repeat: no-repeat;
  }

  input[type="range"]::-webkit-slider-thumb {
    position: relative;
    left: -3px;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #38BDF8;
    cursor: ew-resize;
    // box-shadow: 0px 1px 3px #38BDF8, 0px 1px 2px #38BDF8;
    -webkit-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
  }

  html.dark input[type="range"]::-webkit-slider-thumb,
  html.dark input[type="range"]::-moz-range-thumb,
  html.dark input[type="range"]::-ms-thumb {
    background: #E2E8F0;
  }

  input[type="range"]::-webkit-slider-runnable-track,
  input[type="range"]::-moz-range-track,
  input[type="range"]::-ms-track {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  div#song-saved.saved svg path {
    fill: #38BDF8;
    stroke: #38BDF8;
  }

  div.amplitude-play-pause.amplitude-playing #pause-icon {
    display: block;
  }

  div.amplitude-play-pause.amplitude-paused #pause-icon {
    display: none;
  }

  div.amplitude-play-pause.amplitude-paused #play-icon {
    display: block;
  }

  div.amplitude-play-pause.amplitude-playing #play-icon {
    display: none;
  }

  div.amplitude-shuffle.amplitude-shuffle-on svg path {
    fill: #38BDF8;
  }

  div.amplitude-repeat-song.amplitude-repeat-song-on svg path {
    fill: #38BDF8;
  }

  .hover\:bg-sky-400\/20:hover {
    background-color: rgb(56 189 248 / 0.2);
  }

  .hover\:bg-emerald-400\/20:hover {
    background-color: rgb(52 211 153 / 0.2);
  }

  .dark .dark\:block {
    display: block;
  }

  .dark .dark\:hidden {
    display: none;
  }

  .dark .dark\:border-player-dark-border {
    border-color: rgba(255, 255, 255, 0.16);
  }

  .dark .dark\:border-cover-dark-border {
    border-color: rgba(255, 255, 255, 0.1);
    ;
  }

  .dark .dark\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity));
  }

  .dark .dark\:border-play-pause-dark-border {
    border-color: rgba(255, 255, 255, 0.06);
  }

  .dark .dark\:bg-\[\#0A1122\] {
    --tw-bg-opacity: 1;
    background-color: rgb(10 17 34 / var(--tw-bg-opacity));
  }

  .dark .dark\:bg-player-dark-background {
    background-color: rgba(30, 41, 59, 0.7);
  }

  .dark .dark\:bg-control-panel-dark-background {
    background-color: rgba(23, 31, 50, 0.5);
  }

  .dark .dark\:bg-play-pause-dark-background {
    --tw-bg-opacity: 1;
    background-color: rgb(47 59 79 / var(--tw-bg-opacity));
  }

  .dark .dark\:fill-slate-400 {
    fill: #94a3b8;
  }

  .dark .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .dark .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }

  .dark .dark\:text-sky-300 {
    --tw-text-opacity: 1;
    color: rgb(125 211 252 / var(--tw-text-opacity));
  }

  .dark .dark\:text-sky-400 {
    --tw-text-opacity: 1;
    color: rgb(56 189 248 / var(--tw-text-opacity));
  }

  .dark .dark\:text-sky-400\/70 {
    color: rgb(56 189 248 / 0.7);
  }

  .dark .dark\:text-emerald-400 {
    --tw-text-opacity: 1;
    color: rgb(52 211 153 / var(--tw-text-opacity));
  }

  .dark .dark\:shadow-player-dark {
    --tw-shadow: inset 0px 2px 4px rgba(255, 255, 255, 0.06);
    ;
    --tw-shadow-colored: inset 0px 2px 4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dark .dark\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  @media (min-width: 1280px) {
    .xl\:flex {
      display: flex;
    }
  }
}

.markers-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  pointer-events: none;
  /* so user can still click the bar behind it if you prefer */
}

/* Each marker: a small vertical line or dot */
.marker {
  position: absolute;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: red;
  pointer-events: auto;
  /* allow clicking on the marker */
  cursor: pointer;
}

/* time info */
.time-info {
  margin-bottom: 16px;
}

/* notes input */
.notes-input {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}

.notes-input input {
  flex: 1;
  padding: 4px 8px;
}

.custom-tooltip {
  @apply opacity-0 z-50 rounded-lg shadow-md p-1 bg-black/70 text-white text-[0.75rem] -mt-8 whitespace-nowrap -ml-5 transition-all ease-linear duration-75 absolute;
}

.has-tooltip:hover .custom-tooltip {
  @apply opacity-100;
}