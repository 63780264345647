.min-h:calculated-height {
  &-unsubscribed {
    min-height: calc(100vh - 300px);
  }

  &-subscribed {
    min-height: calc(100vh - 140px);
  }
}

.calculated-height {
  &-unsubscribed {
    height: calc(100vh - 300px);
  }

  &-subscribed {
    height: calc(100vh - 140px);
  }
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  background-size: 200% 200%;
  animation: gradientBG 4s ease infinite;
}

.active-scale-transition-all {
  @apply active:scale-95 transition-all duration-200;
}